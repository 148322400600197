import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { ControlValueAccessor, NgControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldControl, MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { Observable, Subject } from 'rxjs';

import { FormUtils } from '@portal/shared/utils';

@Component({
    selector: 'p-ui-gender-selector',
    templateUrl: './gender-selector.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: GenderSelectorComponent,
        },
        FormUtils.controlValueAccessorFactory(GenderSelectorComponent),
    ],
    imports: [ReactiveFormsModule, MatFormFieldModule, MatSelectModule],
})
export class GenderSelectorComponent implements MatFormFieldControl<string>, ControlValueAccessor {
    readonly #cdr = inject(ChangeDetectorRef);

    value: string | null = null;
    stateChanges: Observable<void> = new Subject();
    id: string = '';
    placeholder: string = '';
    ngControl: NgControl | null = null;
    focused: boolean = false;
    empty: boolean = true;
    shouldLabelFloat: boolean = false;
    required: boolean = false;
    disabled: boolean = false;
    errorState: boolean = false;
    controlType: string | undefined;
    autofilled: boolean | undefined;
    userAriaDescribedBy: string | undefined;
    disableAutomaticLabeling: boolean | undefined;

    genderOptions = [
        { value: 'M', viewValue: 'Male' },
        { value: 'F', viewValue: 'Female' },
        { value: 'O', viewValue: 'Other' },
        { value: 'N', viewValue: 'Not applicable' },
        { value: 'U', viewValue: 'Unknown' },
    ];

    setDescribedByIds(_ids: string[]): void {
        //
    }

    onContainerClick(_event: MouseEvent): void {
        //
    }

    writeValue(_value: string | null): void {
        this.value = _value;
        this.#cdr.markForCheck();
    }

    registerOnChange(fn: (value: string | null) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    private onChange: (value: string | null) => void = () => {
        //
    };

    private onTouched: () => void = () => {
        //
    };

    onSelectChange(value: string): void {
        this.value = value;
        this.onChange(value);
        this.onTouched();
    }
}
