import {
    AfterContentInit,
    ChangeDetectorRef,
    DestroyRef,
    Directive,
    EmbeddedViewRef,
    inject,
    Input,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { map, ReplaySubject, Subject, switchMap } from 'rxjs';

import { TAILWIND_BREAKPOINT_PREFIX, TailwindBreakpointPrefixType } from './bp.model';
import { BpService } from './bp.service';

@Directive({
    selector: '[pUiBpSm],[pUiBpMd],[pUiBpLg],[pUiBpXl],[pUiBpXxl]',
})
export class BpDirective implements AfterContentInit {
    private readonly _context: BpContext = new BpContext();
    private _thenTemplateRef: TemplateRef<BpContext> | null = inject(TemplateRef<BpContext>);
    private _elseTemplateRef: TemplateRef<BpContext> | null = null;
    private _thenViewRef: EmbeddedViewRef<BpContext> | null = null;
    private _elseViewRef: EmbeddedViewRef<BpContext> | null = null;

    private readonly _viewContainer = inject(ViewContainerRef);
    private readonly _bp = inject(BpService);
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _cd = inject(ChangeDetectorRef);

    private readonly _bpSource: Subject<BpContext> = new ReplaySubject<BpContext>(1);

    ngAfterContentInit(): void {
        this._bpSource
            .pipe(
                switchMap((ctx: BpContext) =>
                    this._bp.medias$.pipe(
                        map((medias: TailwindBreakpointPrefixType[]) => {
                            return ctx.matches ? medias.includes(ctx.bp) : !medias.includes(ctx.bp);
                        })
                    )
                ),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe((condition: boolean) => {
                this._updateView(condition);
                this._cd.detectChanges();
            });
    }

    @Input() set pUiBpSm(matches: boolean) {
        this.changeContext({ bp: TAILWIND_BREAKPOINT_PREFIX.SM, matches });
    }

    @Input() set pUiBpMd(matches: boolean) {
        this.changeContext({ bp: TAILWIND_BREAKPOINT_PREFIX.MD, matches });
    }

    @Input() set pUiBpLg(matches: boolean) {
        this.changeContext({ bp: TAILWIND_BREAKPOINT_PREFIX.LG, matches });
    }

    @Input() set pUiBpXl(matches: boolean) {
        this.changeContext({ bp: TAILWIND_BREAKPOINT_PREFIX.XL, matches });
    }

    @Input() set pUiBpXxl(matches: boolean) {
        this.changeContext({ bp: TAILWIND_BREAKPOINT_PREFIX.XXL, matches });
    }

    /**
     * A template to show if the condition expression evaluates to true.
     */
    @Input() set pUiBpThen(templateRef: TemplateRef<BpContext> | null) {
        this._thenTemplateRef = templateRef;
        this._thenViewRef = null; // clear previous view if any.
        this._updateView(true);
    }

    /**
     * A template to show if the condition expression evaluates to false.
     */
    @Input() set pUiBpElse(templateRef: TemplateRef<BpContext> | null) {
        this._elseTemplateRef = templateRef;
        this._elseViewRef = null; // clear previous view if any.
        this._updateView(false);
    }

    private changeContext(ctx: BpContext): void {
        this._context.bp = ctx.bp;
        this._context.matches = ctx.matches;

        this._bpSource.next(ctx);
    }

    private _updateView(condition: boolean) {
        if (condition) {
            if (!this._thenViewRef) {
                this._viewContainer.clear();
                this._elseViewRef = null;
                if (this._thenTemplateRef) {
                    this._thenViewRef = this._viewContainer.createEmbeddedView(this._thenTemplateRef, this._context);
                }
            }
        } else {
            if (!this._elseViewRef) {
                this._viewContainer.clear();
                this._thenViewRef = null;
                if (this._elseTemplateRef) {
                    this._elseViewRef = this._viewContainer.createEmbeddedView(this._elseTemplateRef, this._context);
                }
            }
        }
    }

    /**
     * Asserts the correct type of the context for the template that `IsSmDirective` will render.
     *
     * The presence of this method is a signal to the Ivy template type-check compiler that the
     * `IsSmDirective` structural directive renders its template with a specific context type.
     */
    static ngTemplateContextGuard(dir: BpDirective, ctx: any): ctx is BpContext {
        return true;
    }
}

/**
 * @publicApi
 */
export class BpContext {
    bp: TailwindBreakpointPrefixType = null!;
    matches: boolean = null!;
}
