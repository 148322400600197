import { ChangeDetectionStrategy, Component, inject, output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { MaxLength } from '@portal/shared/utils';

@Component({
    templateUrl: './custom-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatDialogClose,
        MatButtonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogContent,
        MatDialogActions,
        ReactiveFormsModule,
        MatDialogTitle,
    ],
})
export class CustomDialogComponent {
    readonly customLabelChange = output<string>();

    readonly createCustomLabel: FormGroup;
    private readonly fb: FormBuilder = inject(FormBuilder);

    readonly LABEL_NAME_MAX_LENGTH = MaxLength.LABEL_NAME_MAX_LENGTH;

    constructor() {
        this.createCustomLabel = this.fb.group({
            labelName: ['', [Validators.required, Validators.maxLength(this.LABEL_NAME_MAX_LENGTH)]],
        });
    }

    onCreate(): void {
        if (this.createCustomLabel.valid) {
            const labelValue = this.createCustomLabel.get('labelName')!.value as string;
            this.customLabelChange.emit(labelValue);
        }
    }
}
