<div class="flex max-w-96 flex-col items-center">
    <mat-icon class="!flex !h-[72px] !w-[72px] items-center justify-center text-[4rem] text-gray-500" [class.outlined]="isIconOutlined()">{{
        titleIcon()
    }}</mat-icon>
    <div class="text-center text-xl text-gray-500">{{ title() }}</div>
    <!--        <button mat-fab extended [disabled]="disabled" class="tertiary mt-4" (click)="submitCta.emit()">-->
    <!--            <mat-icon class="text-white">{{ ctaIcon }}</mat-icon>-->
    <!--            <div class="text-white">{{ ctaText }}</div>-->
    <!--        </button>-->
</div>
