import { ChangeDetectionStrategy, Component, inject, output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogClose,
    MatDialogConfig,
    MatDialogContent,
    MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { MaxLength } from '@portal/shared/utils';

@Component({
    templateUrl: './note-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatDialogClose,
        MatButtonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogContent,
        MatDialogActions,
        ReactiveFormsModule,
        MatDialogTitle,
    ],
})
export class NoteDialogComponent {
    readonly data = inject<{ note?: string; maxLength?: number; title?: string }>(MAT_DIALOG_DATA);

    static readonly config: MatDialogConfig = {
        maxWidth: '450px',
        width: '90%',
    };

    readonly noteChange = output<string>();
    readonly maxLength = this.data?.maxLength ?? MaxLength.NOTE_MAX_LENGTH;

    readonly createNote: FormGroup;
    private readonly fb: FormBuilder = inject(FormBuilder);

    constructor() {
        const data = this.data;

        this.createNote = this.fb.group({
            note: [data?.note ?? '', [Validators.maxLength(this.maxLength)]],
        });
    }

    onCreate(): void {
        if (this.createNote.valid) {
            const noteValue = this.createNote.get('note')!.value as string;
            this.noteChange.emit(noteValue);
        }
    }
}
