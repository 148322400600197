import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, linkedSignal, output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Observable } from 'rxjs';

import { MAX_FILES_AMOUNT, MAX_SIZE_IN_BYTES } from '@portal/shared/utils';

import { SystemDialogService } from '../system-dialog';

@Component({
    selector: 'p-ui-add-attachements',
    imports: [MatIconModule, MatButtonModule, CommonModule, ReactiveFormsModule],
    templateUrl: './add-attachements.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddAttachementsComponent {
    private systemDialogService = inject(SystemDialogService);
    readonly $isEmpty = input<boolean>(false, { alias: 'isEmpty' });

    readonly $isPanelVisible = linkedSignal(() => this.$isEmpty());
    isDragging = false;
    selectedFile: File | null = null;

    readonly uploadFile = output<File>();
    createErrorFile = output<File>();

    fileControl = new FormControl<File | null>(null);

    onFileSelected(event: Event): void | Observable<void> {
        const input = event.target as HTMLInputElement;

        if (input.files && input.files.length > MAX_FILES_AMOUNT) {
            const filesAmount = input.files.length;
            this.fileControl.reset();

            return this.systemDialogService.notify({
                buttonText: 'Ok',
                title: `${filesAmount} too many files`,
                message: 'You can upload up to ten files at one time.',
            });
        }

        if (input.files && input.files.length > 0) {
            Array.from(input.files).forEach((file) => {
                if (file.size < MAX_SIZE_IN_BYTES) {
                    this.uploadAttachement(file);
                } else {
                    this.createErrorFile.emit(file);
                }
            });
        }

        this.fileControl.reset();
    }

    onDragOver(event: DragEvent): void {
        event.preventDefault();
        this.isDragging = true;
    }

    onDragLeave(event: DragEvent): void {
        this.isDragging = false;
    }

    onDrop(event: DragEvent): void | Observable<void> {
        event.preventDefault();
        this.isDragging = false;
        if (event.dataTransfer && event.dataTransfer.files.length > MAX_FILES_AMOUNT) {
            this.fileControl.reset();

            return this.systemDialogService.notify({
                buttonText: 'Ok',
                title: `${event.dataTransfer.files.length} too many files`,
                message: 'You can upload up to ten files at one time.',
            });
        }
        if (event.dataTransfer && event.dataTransfer.files.length > 0) {
            Array.from(event.dataTransfer.files).forEach((file) => {
                if (file.size < MAX_SIZE_IN_BYTES) {
                    this.uploadAttachement(file);
                } else {
                    this.createErrorFile.emit(file);
                }
            });
        }

        this.fileControl.reset();
    }

    uploadAttachement(file: File): void {
        this.uploadFile.emit(file);
        this.$isPanelVisible.set(false);
    }

    onChangeVisibility() {
        this.$isPanelVisible.update((value) => !value);
    }
}
