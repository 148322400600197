import { Directive, ElementRef, HostListener, inject, Input, OnChanges } from '@angular/core';
import { NgControl } from '@angular/forms';

import { IsNotNullish } from '@portal/shared/utils';

@Directive({
    selector: 'input[type="number"][pUiMinMaxValue]',
})
export class MinMaxValueDirective implements OnChanges {
    @Input() minValue?: number | null;
    @Input() maxValue?: number | null;

    private readonly elementRef = inject<ElementRef<HTMLInputElement>>(ElementRef);
    private readonly control = inject(NgControl, { optional: true, self: true });

    @HostListener('blur')
    onBlur(): void {
        this.validate();
    }

    ngOnChanges(): void {
        this.validate();
    }

    validate(): void {
        if (typeof this.minValue === 'number') {
            if (IsNotNullish(this.control?.control)) {
                const value: any = this.control.control.value;
                const isNumber: boolean = typeof value === 'number' && !isNaN(value);
                if (!isNumber || (isNumber && value < this.minValue)) {
                    this.control.control.patchValue(this.minValue);
                }
            } else {
                const value: any = parseFloat(this.elementRef.nativeElement.value);
                const isNumber: boolean = !isNaN(value);
                if (!isNumber || (isNumber && value < this.minValue)) {
                    this.elementRef.nativeElement.value = this.minValue.toString();
                }
            }
        }

        if (typeof this.maxValue === 'number') {
            if (IsNotNullish(this.control?.control)) {
                const value: any = this.control.control.value;
                if (value > this.maxValue) {
                    this.control.control.patchValue(this.maxValue);
                }
            } else {
                const value: any = parseFloat(this.elementRef.nativeElement.value);
                if (value > this.maxValue) {
                    this.elementRef.nativeElement.value = this.maxValue.toString();
                }
            }
        }
    }
}
