import { ChangeDetectionStrategy, Component, output, input } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { transformToNumber } from '@portal/shared/utils';

@Component({
    selector: 'p-ui-pagination',
    templateUrl: './pagination.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule, MatIconButton],
})
export class PaginationComponent {
    readonly currentPage = input.required<number, number | null | undefined>({ transform: transformToNumber });
    readonly totalPages = input.required<number, number | null | undefined>({ transform: transformToNumber });
    readonly pageChange = output<number>();

    onNext() {
        if (this.currentPage() < this.totalPages() - 1) {
            this.pageChange.emit(this.currentPage() + 1);
        }
    }

    onPrevious() {
        if (this.currentPage() > 0) {
            this.pageChange.emit(this.currentPage() - 1);
        }
    }
}
