import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, computed } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';

import { FormatLoginDatePipe, HasItemsPipe, LocalDateTimeUtils, LoginHistoryEntity, TimeAgoPipe, UserEntity } from '@portal/shared/utils';

@Component({
    selector: 'p-ui-user-login-history',
    templateUrl: './user-login-history.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, FormatLoginDatePipe, HasItemsPipe, MatIcon, MatTableModule, TimeAgoPipe],
})
export class UserLoginHistoryComponent {
    readonly displayedColumns = ['ip', 'date'];

    readonly $user = input.required<UserEntity>({ alias: 'user' });
    readonly $loginHistory = computed<LoginHistoryEntity[]>((user = this.$user()) => {
        return [...user.loginHistoryDto].sort((a, b) => {
            return LocalDateTimeUtils.fromDateTimeFormat(b.loginDate) >= LocalDateTimeUtils.fromDateTimeFormat(a.loginDate) ? 1 : -1;
        });
    });
}
