<mat-form-field appearance="outline" class="w-full">
    <mat-label>{{ label }}</mat-label>

    <mat-chip-grid #chipGridRef [attr.aria-label]="label">
        @for (item of valueSelectedItems$ | async; track item) {
            <mat-chip-row (removed)="onRemoveItem(item)">
                @if (displayViewTpl) {
                    <ng-container [ngTemplateOutlet]="displayViewTpl.templateRef" [ngTemplateOutletContext]="{ $implicit: item }" />
                }

                <button matChipRemove [disabled]="disabled" aria-label="remove">
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-row>
        }

        @let inputPlaceholder = (allItemsSelected$ | async) ? '' : (placeholder ?? '');
        <input
            #inputRef
            [placeholder]="inputPlaceholder"
            [formControl]="inputControl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipGridRef"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="onAddItem($event)"
        />
    </mat-chip-grid>

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectItem($event)">
        @for (item of filteredList$ | async; track item) {
            <mat-option [value]="item">
                @if (displayViewTpl) {
                    <ng-container *ngTemplateOutlet="displayViewTpl.templateRef; context: { $implicit: item }" />
                }
            </mat-option>
        }
        @if (inputControl.value !== '' && (filteredList$ | async)?.length === 0) {
            <mat-option disabled>Nothing found. </mat-option>
        }
    </mat-autocomplete>
</mat-form-field>
