import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, output, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'p-ui-placeholder',
    templateUrl: './placeholder.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule],
    styles: [
        `
            :host {
                pointer-events: none;
            }
        `,
    ],
})
export class PlaceholderComponent {
    readonly titleIcon = input.required<string>();
    readonly title = input.required<string>();
    readonly ctaIcon = input.required<string>();
    readonly ctaText = input.required<string>();
    readonly isIconOutlined = input<boolean>();
    readonly disabled = input<boolean, any>(false, { transform: coerceBooleanProperty });

    readonly submitCta = output<void>();
}
