/* eslint-disable @angular-eslint/no-output-rename */
import {
    booleanAttribute,
    ChangeDetectionStrategy,
    Component,
    effect,
    ElementRef,
    input,
    output,
    Signal,
    signal,
    viewChild,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { IsNotNull } from '@portal/shared/utils';

@Component({
    selector: 'p-ui-search-bar',
    templateUrl: './search-bar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatFormFieldModule, ReactiveFormsModule, MatInputModule, FormsModule, MatButtonModule, MatIconModule],
})
export class SearchBarComponent {
    protected readonly $input: Signal<ElementRef<HTMLInputElement>> = viewChild.required('input');

    readonly $instant = input(false, { alias: 'instant', transform: booleanAttribute });
    readonly $placeholder = input('Search', { alias: 'placeholder' });
    readonly $value = input<string | null>(null, { alias: 'value' });

    readonly searchTerm = output<string>({ alias: 'searchQuery' });
    readonly searchTermReset = output<void>();
    readonly closed = output<void>();

    readonly formControl: FormControl<string | null> = new FormControl<string | null>(null);
    readonly #focusSource = signal(false);

    constructor() {
        effect(() => {
            const currentValue = this.$value();
            if (IsNotNull(currentValue)) {
                this.formControl.setValue(currentValue);
            }
        });
    }

    onSubmit($event: Event) {
        $event.preventDefault();
        this.#search();
    }

    onFocus(): void {
        this.#focus();
    }

    onBlur(): void {
        this.#focusSource.set(false);
    }

    onSearch() {
        this.#search();
    }

    onClear(): void {
        this.#clear();
        this.searchTermReset.emit();
        this.#focus();
    }

    onClose(): void {
        this.#clear();
        this.searchTermReset.emit();
        this.closed.emit();
    }

    onEscape() {
        this.onClose();
        this.$input().nativeElement.blur();
    }

    #focus(): void {
        this.$input().nativeElement.focus();
        this.#focusSource.set(true);
    }

    #search(): void {
        const currentSearch = this.#getCurrentSearch();
        if (!currentSearch) {
            this.searchTermReset.emit();
        } else {
            this.searchTerm.emit(currentSearch);
        }
    }

    #getCurrentSearch(): string | null {
        const searchValue = this.formControl.value?.trim() ?? '';
        return searchValue !== '' ? searchValue : null;
    }

    #clear(): void {
        this.formControl.reset();
    }
}
