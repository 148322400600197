import { Directive, inject, TemplateRef, input } from '@angular/core';

import { ContainerContentType, ContainerType } from './container.utils';

@Directive({
    selector: '[pUiContainerContent]',
})
export class ContainerContentDirective implements ContainerType<ContainerContentType> {
    readonly $contentType = input.required<ContainerContentType>({ alias: 'pUiContainerContent' });
    readonly $opened = input<boolean>(false, { alias: 'pUiContainerContentOpened' });

    readonly templateRef = inject(TemplateRef);

    getContainerType(): ContainerContentType {
        return this.$contentType();
    }
}
