import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    inject,
    input,
    OnInit,
    signal,
    Pipe,
    PipeTransform,
    computed,
    ViewContainerRef,
    ChangeDetectorRef,
    booleanAttribute,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    ControlValueAccessor,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { ContentLoaderSnippet } from '@common/ui';

import { ADD_ADDRESS_DIALOG, ContactAddressDto, COUNTRIES_STORE, CountryEntity, MaxLength } from '@portal/shared/utils';

@Pipe({
    name: 'countryConfig',
})
export class CountryConfigPipe implements PipeTransform {
    transform(value: string | null, countriesState: Record<string, CountryEntity>): CountryEntity | null {
        const countryEntry = Object.entries(countriesState).find(([countryName, country]) => countryName === value) ?? null;

        return countryEntry ? countryEntry[1] : null;
    }
}

@Component({
    selector: 'p-ui-address-form',
    templateUrl: './address-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AddressFormComponent,
            multi: true,
        },
    ],
    imports: [
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatSelectModule,
        ContentLoaderSnippet,
        CountryConfigPipe,
    ],
})
export class AddressFormComponent implements ControlValueAccessor, OnInit {
    private readonly destroyRef: DestroyRef = inject(DestroyRef);
    readonly #cdr = inject(ChangeDetectorRef);
    private readonly fb = inject(FormBuilder);
    readonly #viewContainerRef = inject(ViewContainerRef);
    private addressDialog = inject(ADD_ADDRESS_DIALOG);
    readonly MaxLength = MaxLength;

    readonly $defaultAddressLabel = input<string>('Home', { alias: 'defaultAddressLabel' });
    readonly $withSingleAddress = input(false, { alias: 'withSingleAddress', transform: booleanAttribute });
    readonly $addButtonLabel = input<string>('Add address', { alias: 'addButtonLabel' });

    private countriesStore = inject(COUNTRIES_STORE);

    $countries = this.countriesStore.countries;
    $countriesLoading = this.countriesStore.countriesLoading;
    $countryLoading = this.countriesStore.countryLoading;
    readonly $countriesState = signal<Record<string, CountryEntity>>({});

    readonly $addressFormArray = input.required<FormArray>({ alias: 'addressFormArray' });
    readonly $addressOptions = input<string[]>([], { alias: 'addressOptions' });

    readonly $addressFormGroups = computed(() => this.$addressFormArray().controls as FormGroup[]);

    ngOnInit() {
        if (this.$countries().length === 0) {
            this.countriesStore.read();

            this.countriesStore
                .onReadCountriesSuccess()
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe(() => this.initCountryConfig());
        } else {
            this.initCountryConfig();
        }

        this.initPostalCodeFormattingSubscription();

        this.countriesStore
            .onReadCountryByCodeSuccess()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((country) => {
                this.$countriesState.update((state) => ({ ...state, [country.country]: country }));
            });
    }

    onDeleteAddress(index: number): void {
        this.$addressFormArray().removeAt(index);
        this.$addressFormArray().markAsDirty();
    }

    private initPostalCodeFormattingSubscription() {
        this.$addressFormGroups().forEach((formGroup) => {
            const postalCodeControl = formGroup.get('postalCode');

            postalCodeControl?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value: string | null) => {
                if (value) {
                    const formattedValue = value.replace(/[^a-zA-Z0-9 ]/g, '').toUpperCase();
                    postalCodeControl.setValue(formattedValue, { emitEvent: false });
                }
            });
        });
    }

    private initCountryConfig() {
        this.$addressFormGroups().forEach((formGroup) => {
            const countryControlValue = formGroup.get('country')?.value;

            formGroup.addControl('initialCountry', new FormControl(countryControlValue));

            const countryCode = this.$countries().find((countryItem) => countryItem.countryName === countryControlValue)?.countryCode;

            if (countryCode) {
                this.countriesStore.readByCode(countryCode);
            }
        });
    }

    addAddressToForm(address: ContactAddressDto): void {
        const addressGroup = this.fb.group({
            street: [address.street],
            poBox: [address.poBox],
            city: [address.city],
            state: [address.state],
            postalCode: [address.postalCode],
            country: [address.country],
            label: [address.label],
        });
        this.$addressFormArray().push(addressGroup);
        this.#cdr.markForCheck();
    }

    onAddAddressDialog() {
        const dialogRef = this.addressDialog.open({
            label: this.$defaultAddressLabel(),
            viewContainerRef: this.#viewContainerRef,
        });

        const sub = dialogRef.componentInstance.addAddress.subscribe((address) => {
            this.addAddressToForm(address);
            dialogRef.close();
        });

        this.destroyRef.onDestroy(() => sub.unsubscribe());
    }

    writeValue(fn: any): void {
        //
    }

    registerOnChange(fn: any): void {
        //
    }

    registerOnTouched(fn: any): void {
        //
    }
}
