import { CdkPortalOutlet, ComponentPortal, DomPortal, Portal, TemplatePortal } from '@angular/cdk/portal';
import { AsyncPipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    ContentChildren,
    DestroyRef,
    Directive,
    inject,
    Injectable,
    OnInit,
    output,
    QueryList,
    Signal,
    TemplateRef,
    ViewContainerRef,
    viewChild,
    signal,
    forwardRef,
    Injector,
    input,
    AfterViewInit,
    InjectionToken,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatDrawerMode, MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { map, merge, Observable, pipe, ReplaySubject, Subject, UnaryFunction } from 'rxjs';

import { IsNotUndefined } from '@portal/shared/utils';

import { BpService } from '../bp';
import { ContainerContentDirective } from './container-content.directive';
import { ContainerPanelDirective } from './container-panel.directive';
import { CONTAINER_SHELL_HOST, ContainerContentType, ContainerPanelType, ContainerShellHost, ContainerType } from './container.utils';

export type CommonPortal = TemplatePortal | ComponentPortal<any> | DomPortal;
export const COMMON_PORTAL_DATA = new InjectionToken<any>('CommonPortalDataToken');

@Injectable()
export class ContainerShellApiService {
    private readonly _containerShellBloc = inject(ContainerShellBloc);

    setSidenav(templateRef: TemplateRef<any>): void {
        this._containerShellBloc.setSidenav(templateRef);
    }

    toggleSidenav(): void {
        this._containerShellBloc.toggleSidenav();
    }

    openSidenav(): void {
        this._containerShellBloc.openSidenav();
    }

    closeSidenav(): void {
        this._containerShellBloc.closeSidenav();
    }

    setDrawer(templateRef: TemplateRef<any> | CommonPortal): void {
        this._containerShellBloc.setDrawer(templateRef);
    }

    toggleDrawer(): void {
        this._containerShellBloc.toggleDrawer();
    }

    openDrawer(): void {
        this._containerShellBloc.openDrawer();
    }

    closeDrawer(): void {
        this._containerShellBloc.closeDrawer();
    }

    drawerOpenedChanges(): Observable<boolean> {
        return this._containerShellBloc.drawerOpenedChanges();
    }

    backdropClickChanges(): Observable<void> {
        return this._containerShellBloc.backdropClickChanges();
    }
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'p-ui-container-shell[api]',
    exportAs: 'api',
})
export class ContainerShellApiDirective extends ContainerShellApiService {
    readonly backdropClick = output<void>();

    constructor() {
        super();

        this.backdropClickChanges()
            .pipe(takeUntilDestroyed())
            .subscribe(() => this.backdropClick.emit());
    }
}

@Injectable()
class ContainerShellBloc {
    private readonly _sidenavSource: Subject<TemplateRef<any>> = new Subject<TemplateRef<any>>();
    private readonly _toggleSidenavSource: Subject<void> = new Subject<void>();
    private readonly _openedSidenavSource: Subject<void> = new Subject<void>();
    private readonly _closedSidenavSource: Subject<void> = new Subject<void>();

    private readonly _drawerSource = new Subject<TemplateRef<any> | CommonPortal>();
    private readonly _toggleDrawerSource: Subject<void> = new Subject<void>();
    private readonly _openDrawerActionSource: Subject<void> = new Subject<void>();
    private readonly _closedDrawerSource: Subject<void> = new Subject<void>();
    private readonly _drawerOpenedSource$: Subject<boolean> = new ReplaySubject<boolean>(1);

    private readonly _backdropClickSource$: Subject<void> = new Subject<void>();

    readonly sidenavChanges$: Observable<TemplateRef<any>> = this._sidenavSource.asObservable();
    readonly toggleSidenavChanges$: Observable<void> = this._toggleSidenavSource.asObservable();
    readonly openSidenavChanges$: Observable<void> = this._openedSidenavSource.asObservable();
    readonly closeSidenavChanges$: Observable<void> = this._closedSidenavSource.asObservable();

    readonly drawerChanges$: Observable<TemplateRef<any> | CommonPortal> = this._drawerSource.asObservable();
    readonly toggleDrawerChanges$: Observable<void> = this._toggleDrawerSource.asObservable();
    readonly openDrawerAction$: Observable<void> = this._openDrawerActionSource.asObservable();
    readonly closeDrawerChanges$: Observable<void> = this._closedDrawerSource.asObservable();

    // sidenav

    setSidenav(templateRef: TemplateRef<any>): void {
        this._sidenavSource.next(templateRef);
    }

    toggleSidenav(): void {
        this._toggleSidenavSource.next();
    }

    openSidenav(): void {
        this._openedSidenavSource.next();
    }

    closeSidenav(): void {
        this._closedSidenavSource.next();
    }

    // drawer

    setDrawer(templateRef: TemplateRef<any> | CommonPortal, backdropClick: VoidFunction = (): void => void 0): void {
        this._drawerSource.next(templateRef);
    }

    toggleDrawer(): void {
        this._toggleDrawerSource.next();
    }

    openDrawer(): void {
        this._openDrawerActionSource.next();
    }

    closeDrawer(): void {
        this._closedDrawerSource.next();
    }

    setDrawerOpened(opened: boolean): void {
        this._drawerOpenedSource$.next(opened);
    }

    drawerOpenedChanges(): Observable<boolean> {
        return this._drawerOpenedSource$.asObservable();
    }

    backdropClickAction(): void {
        this._backdropClickSource$.next();
    }

    backdropClickChanges(): Observable<void> {
        return this._backdropClickSource$.asObservable();
    }
}

@Component({
    selector: 'p-ui-container-shell',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatSidenav, MatSidenavModule, AsyncPipe, CdkPortalOutlet],
    providers: [
        ContainerShellApiService,
        ContainerShellBloc,
        {
            provide: CONTAINER_SHELL_HOST,
            useExisting: forwardRef(() => ContainerShellComponent),
        },
    ],
    templateUrl: './container-shell.component.html',
})
export class ContainerShellComponent implements ContainerShellHost, OnInit, AfterViewInit {
    readonly #parentInjector = inject(Injector, { skipSelf: true });
    readonly #viewContainerRef = inject(ViewContainerRef);

    readonly $hostName = input<string | undefined>(undefined, { alias: 'hostName' });
    readonly $matSidenav = viewChild.required('matSidenavRef', { read: MatSidenav });
    readonly $matDrawer = viewChild.required('matDrawerRef', { read: MatSidenav });

    private readonly _contentsSource: Subject<QueryList<ContainerContentDirective>> = new ReplaySubject<
        QueryList<ContainerContentDirective>
    >(1);
    private readonly _panelsSource: Subject<QueryList<ContainerPanelDirective>> = new ReplaySubject<QueryList<ContainerPanelDirective>>(1);

    @ContentChildren(ContainerContentDirective, { read: ContainerContentDirective })
    set contents(value: QueryList<ContainerContentDirective>) {
        if (value) {
            this._contentsSource.next(value);
        }
    }

    @ContentChildren(ContainerPanelDirective, { read: ContainerPanelDirective })
    set panels(value: QueryList<ContainerPanelDirective>) {
        if (value) {
            this._panelsSource.next(value);
        }
    }

    readonly #bp = inject(BpService);
    readonly #bloc = inject(ContainerShellBloc);
    readonly #destroyRef = inject(DestroyRef);

    readonly footerTpl$: Observable<TemplatePortal | null> = this.#contentChanges('footer');
    readonly asideTpl$: Observable<TemplatePortal | null> = this.#contentChanges('aside');
    readonly contentTpl$: Observable<TemplatePortal | null> = this.#contentChanges('content');

    readonly panelSidenavOpened$: Observable<boolean> = this.#panelOpenedChanges('sidenav');
    readonly panelSidenavTpl$: Observable<TemplatePortal | null> = merge(this.#bloc.sidenavChanges$, this.#panelChanges('sidenav')).pipe(
        map((templateRef: TemplateRef<any> | null) => (templateRef ? new TemplatePortal(templateRef, this.#viewContainerRef) : null))
    );

    readonly #dynamicPanelDrawerSource = new ReplaySubject<TemplateRef<any> | null>(1);
    readonly panelDrawerOpened$: Observable<boolean> = this.#panelOpenedChanges('drawer');
    readonly #panelDrawerPortalSource = signal<Portal<any> | null>(null);
    readonly $panelDrawerPortal = this.#panelDrawerPortalSource.asReadonly();

    readonly #$isDesktop: Signal<boolean | undefined> = toSignal(this.#bp.isDesktop());

    readonly $sidenavMode: Signal<MatDrawerMode> = toSignal(
        this.#bp.isMobile().pipe(map((isMobile: boolean) => (isMobile ? 'over' : 'side'))),
        { initialValue: 'over' }
    );

    // Drawer mode
    readonly #dynamicDrawerMode: Signal<MatDrawerMode> = toSignal(this.#bp.minXL().pipe(map((lg: boolean) => (lg ? 'side' : 'over'))), {
        initialValue: 'side',
    });
    readonly #userDrawerMode: Signal<MatDrawerMode | null> = toSignal(this.#panelModeChanges('drawer'), { initialValue: null });
    readonly $drawerMode: Signal<MatDrawerMode> = computed(() => {
        const dynamicMode = this.#dynamicDrawerMode();
        const userMode: MatDrawerMode | null = this.#userDrawerMode();

        return userMode ?? dynamicMode;
    });

    private static filterByContent<T, R extends ContainerType<T>>(
        contentType: T
    ): UnaryFunction<Observable<QueryList<R>>, Observable<R | undefined>> {
        return pipe(map((directive: QueryList<R>) => directive.find((directive: R) => directive.getContainerType() === contentType)));
    }

    readonly #sidenavOpenedSource: Subject<boolean> = new ReplaySubject<boolean>(1);
    readonly $sidenavOpened: Signal<boolean> = toSignal(merge(this.#sidenavOpenedSource, this.panelSidenavOpened$), {
        initialValue: false,
    });

    readonly #drawerOpenedSource: Subject<boolean> = new ReplaySubject<boolean>(1);
    readonly $drawerOpened: Signal<boolean> = toSignal(this.#bloc.drawerOpenedChanges(), {
        initialValue: false,
    });

    getHost(hostName?: string): ContainerShellHost | null {
        if (!IsNotUndefined(hostName) && this.$hostName() !== hostName) {
            return this.#parentInjector.get(CONTAINER_SHELL_HOST, null)?.getHost(hostName) ?? null;
        } else {
            return this;
        }
    }

    addPanel(panelType: ContainerPanelType, templateRef: TemplateRef<any>, hostName?: string): void {
        if (this.$hostName() !== hostName) {
            return;
        }

        if (panelType === 'sidenav') {
            //
        }
        if (panelType === 'drawer') {
            this.#dynamicPanelDrawerSource.next(templateRef);
        }
    }

    removePanel(panelType: ContainerPanelType, hostName?: string): void {
        if (this.$hostName() !== hostName) {
            return;
        }

        if (panelType === 'sidenav') {
            //
        }
        if (panelType === 'drawer') {
            this.#dynamicPanelDrawerSource.next(null);
        }
    }

    ngOnInit(): void {
        this.#bloc.toggleSidenavChanges$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => void this.$matSidenav().toggle());
        this.#bloc.toggleDrawerChanges$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => void this.$matDrawer().toggle());

        this.#bloc.openSidenavChanges$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => void this.$matSidenav().open());
        this.#bloc.openDrawerAction$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => void this.$matDrawer().open());

        this.#bloc.closeSidenavChanges$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => void this.$matSidenav().close());
        this.#bloc.closeDrawerChanges$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => void this.$matDrawer().close());

        merge(this.#bloc.drawerChanges$, this.#panelChanges('drawer'))
            .pipe(
                map((templateRef: TemplateRef<any> | CommonPortal | null) => {
                    if (templateRef instanceof Portal) {
                        return templateRef;
                    } else {
                        return templateRef ? new TemplatePortal(templateRef, this.#viewContainerRef) : null;
                    }
                }),
                takeUntilDestroyed(this.#destroyRef)
            )
            .subscribe((portal: CommonPortal | null) => {
                this.#panelDrawerPortalSource.set(portal);
            });

        merge(this.#drawerOpenedSource, this.panelDrawerOpened$)
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe((drawerOpened: boolean) => {
                this.#bloc.setDrawerOpened(drawerOpened);
            });
    }

    ngAfterViewInit(): void {
        this.#dynamicPanelDrawerSource
            .pipe(
                map((templateRef: TemplateRef<any> | null) =>
                    templateRef ? new TemplatePortal(templateRef, this.#viewContainerRef) : null
                ),
                takeUntilDestroyed(this.#destroyRef)
            )
            .subscribe((portal: CommonPortal | null) => {
                this.#panelDrawerPortalSource.set(portal);
                if (portal) {
                    this.#bloc.openDrawer();
                } else {
                    this.#bloc.closeDrawer();
                }
            });
    }

    onSidenavOpen(): void {
        this.#sidenavOpenedSource.next(true);
    }

    onSidenavOpenChange(opened: boolean): void {
        if (!opened) {
            this.#sidenavOpenedSource.next(false);
        }
    }

    onDrawerOpen(): void {
        this.#drawerOpenedSource.next(true);
    }

    onDrawerOpenChange(opened: boolean): void {
        if (!opened) {
            this.#drawerOpenedSource.next(false);
        }
    }

    onBackdropClick(): void {
        this.#bloc.backdropClickAction();
    }

    #contentChanges(contentType: ContainerContentType): Observable<TemplatePortal | null> {
        return this._contentsSource.pipe(
            ContainerShellComponent.filterByContent(contentType),
            map((directive: ContainerContentDirective | undefined) => directive?.templateRef ?? null),
            map((templateRef: TemplateRef<any> | null) => (templateRef ? new TemplatePortal(templateRef, this.#viewContainerRef) : null))
        );
    }

    #panelChanges(contentType: ContainerPanelType): Observable<TemplateRef<any> | null> {
        return this._panelsSource.pipe(
            ContainerShellComponent.filterByContent(contentType),
            map((directive: ContainerPanelDirective | undefined) => directive?.templateRef ?? null)
        );
    }

    #panelOpenedChanges(contentType: ContainerPanelType): Observable<boolean> {
        return this._panelsSource.pipe(
            ContainerShellComponent.filterByContent(contentType),
            map((panel: ContainerPanelDirective | undefined) => !!this.#$isDesktop() && !!panel?.$opened())
        );
    }

    #panelModeChanges(contentType: ContainerPanelType): Observable<MatDrawerMode | null> {
        return this._panelsSource.pipe(
            ContainerShellComponent.filterByContent(contentType),
            map((panel: ContainerPanelDirective | undefined) => panel?.mode() ?? null)
        );
    }
}
