import { InjectionToken, TemplateRef } from '@angular/core';

export type ContainerPanelType = 'sidenav' | 'drawer';
export type ContainerContentType = 'aside' | 'footer' | 'content';

export interface ContainerType<T> {
    getContainerType(): T;
    templateRef: TemplateRef<any>;
}

export interface ContainerShellHost {
    /**
     * Retrieves the host associated with the given hostName. If no hostName is provided, it may return the default host or null.
     *
     * @param {string} [hostName] - The optional name of the host to retrieve.
     * @return {ContainerShellHost | null} The corresponding host object if found, or null if no host matches the provided name.
     */
    getHost(hostName?: string): ContainerShellHost | null;

    /**
     * Adds a new panel to the container.
     *
     * @param {ContainerPanelType} panelType - The type of panel to be added.
     * @param {TemplateRef<any>} templateRef - The template reference for the panel.
     * @param {string} [hostName] - Optional host name to associate with the panel.
     * @return {void} This method does not return a value.
     */
    addPanel(panelType: ContainerPanelType, templateRef: TemplateRef<any>, hostName?: string): void;

    /**
     * Removes a panel of the specified type, optionally from a given host.
     *
     * @param {ContainerPanelType} panelType - The type of panel to remove.
     * @param {string} [hostName] - The name of the host from which the panel will be removed. If not provided, removes the panel globally.
     * @return {void} This method does not return a value.
     */
    removePanel(panelType: ContainerPanelType, hostName?: string): void;
}

export const CONTAINER_SHELL_HOST = new InjectionToken<ContainerShellHost>(
    'An injection token used to provide and access the ContainerShellHost.'
);
