<h2 mat-dialog-title class="ml-4 mt-4 font-bold">{{ data.title }}</h2>
<mat-dialog-content>
    <form [formGroup]="createNote" novalidate>
        <mat-form-field class="mt-2 w-full !min-w-[240px]" appearance="outline">
            <mat-label>Note</mat-label>
            <textarea matInput #message [maxlength]="maxLength" formControlName="note" rows="12"></textarea>
            <mat-hint align="start">Max {{ maxLength }} characters</mat-hint>
            <mat-hint align="end">{{ message.value.length }} / {{ maxLength }}</mat-hint>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="mb-4 mr-4 flex w-full justify-end">
        <button mat-button type="button" mat-dialog-close>Cancel</button>
        <button mat-raised-button class="primary" mat-dialog-close (click)="onCreate()">Save</button>
    </div>
</mat-dialog-actions>
