import { ESCAPE, hasModifierKey } from '@angular/cdk/keycodes';
import { ComponentType } from '@angular/cdk/portal';
import { inject, Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { filter, map, merge, Subject, switchMap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IsTrue } from '@portal/shared/utils';

import { SystemDialogService } from '../system-dialog';

/**
 * This service extends MatDialog to incorporate confirmation logic before closing dialogs.
 * It is designed to manage the state of dialog closure based on conditions, such as form changes within the dialog.
 *
 * Usage Examples:
 * 1. Handling form state changes:
 *    To apply form state changes and control the dialog's ability to close, use the following code snippet:
 *    ```typescript
 *    this.formGroup.events
 *        .pipe(
 *            filter((event) => event instanceof PristineChangeEvent),
 *            takeUntilDestroyed(this._destroyRef)
 *        )
 *        .subscribe((event: PristineChangeEvent) => {
 *            this._dialogRef.disableClose = !event.pristine;
 *        });
 *    ```
 *    This ensures that the dialog can only be closed if the form is in a pristine state.
 *
 * 2. Closing the dialog:
 *    Ensure `this._dialogRef.disableClose` is set to `false` to allow the dialog to close. This is crucial to prevent closing the dialog when there may be unsaved changes or other conditions preventing closure.
 *
 */
@Injectable({ providedIn: 'root' })
export class ConfirmMatDialog extends MatDialog {
    readonly #systemDialog = inject(SystemDialogService);

    override open<T, D = any, R = any>(
        componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
        config?: MatDialogConfig<D>
    ): MatDialogRef<T, R> {
        const dialogRef = super.open<T, D, R>(componentOrTemplateRef, config);

        const dialogRefClose = dialogRef.close.bind(dialogRef);

        const confirmCloseSource = new Subject<R | undefined>();
        confirmCloseSource
            .pipe(
                switchMap((dialogResult?: R) =>
                    this.#systemDialog.defaultDiscardConfirm().pipe(
                        filter(IsTrue),
                        map(() => dialogResult)
                    )
                ),
                takeUntil(dialogRef.afterClosed())
            )
            .subscribe((dialogResult?: R) => {
                dialogRefClose(dialogResult);
            });

        dialogRef.close = (dialogResult?: R) => {
            if (dialogRef.disableClose) {
                confirmCloseSource.next(dialogResult);
            } else {
                dialogRefClose(dialogResult);
            }
        };

        merge(
            dialogRef.backdropClick(),
            dialogRef.keydownEvents().pipe(filter((event: KeyboardEvent) => event.keyCode === ESCAPE && !hasModifierKey(event)))
        )
            .pipe(takeUntil(dialogRef.afterClosed()))
            .subscribe(() => {
                dialogRef.close();
            });

        return dialogRef;
    }
}
