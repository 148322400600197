/* eslint-disable @angular-eslint/no-output-rename */

import { ComponentType, OverlayConfig } from '@angular/cdk/overlay';
import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    DestroyRef,
    Directive,
    HostListener,
    inject,
    Input,
    OnDestroy,
    TemplateRef,
    output,
    input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { asyncScheduler, filter, fromEvent, observeOn, Subscription } from 'rxjs';

import { IsNotUndefined, ScreenUtils } from '@portal/shared/utils';

import { BaseTooltip } from '../overlay/base-overlay/base-tooltip';
import { PortalPlacement } from '../overlay/constants/portal-placement';
import { PortalTemplateProps } from '../overlay/interfaces/portal-template-props';
import { PUIPortalComponent } from '../portal';

@Directive({
    selector: '[pUiTooltip]',
    exportAs: 'tooltip',
})
export class PUITooltipDirective extends BaseTooltip implements AfterViewInit, AfterViewChecked, OnDestroy {
    protected readonly cdRef = inject(ChangeDetectorRef);

    @Input({
        alias: 'pUiTooltip',
        required: true,
    })
    override displayValue!: TemplateRef<PortalTemplateProps> | string;
    @Input({ alias: 'pUiTooltipPlacement' }) override placement: PortalPlacement = PortalPlacement.TopCenter;
    @Input({ alias: 'pUiTooltipConfig' }) override config: OverlayConfig | undefined;
    @Input({ alias: 'pUiTooltipProps' }) override templateProps: PortalTemplateProps['props'] | undefined;
    readonly detachedEventHost = input<Element>(undefined, { alias: 'pUiTooltipEventHost' });
    readonly isInteractive = input<boolean>(false, { alias: 'pUiTooltipInteractive' });
    readonly isClickable = input<boolean>(false, { alias: 'pUiTooltipClickable' });
    @Input({ alias: 'pUiTooltipForceStopDisplaying' }) forceStopDisplaying: boolean = false;
    readonly collapsibleTextLogic = input<boolean>(false, { alias: 'pUiTooltipCollapsibleTextLogic' });
    readonly stateChange = output<boolean>({ alias: 'pUiTooltipOnStateChange' });

    protected override ComponentToAttach: ComponentType<PUIPortalComponent> = PUIPortalComponent;

    private readonly _destroyRef = inject(DestroyRef);

    private waitingForDoubleClick: boolean | undefined;
    private shouldSkipEvent: boolean | undefined;
    private timeout?: number;
    private doubleClickTimeout?: number;
    private readonly mobileShowDelay: number = 300;
    private readonly interactiveHideDelay: number = 150;
    private onMobileShowFunc: (event: PointerEvent) => void = this.onMobileShow.bind(this);

    private _outsideClickSubs?: Subscription;
    private _pointerenterSub?: Subscription;
    private _pointerleaveSub?: Subscription;

    private eventListenerOnHide: (event?: PointerEvent) => void = (event?: PointerEvent) => {
        if (event?.relatedTarget === null || !this.detachedEventHost()?.contains(event?.relatedTarget as Node)) {
            this.onHideEvent(event);
        }
    };

    private eventListenerOnShow: (event?: PointerEvent) => void = (event?: PointerEvent) => this.onShowEvent(event);

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.elementRef.nativeElement.removeEventListener('click', this.onMobileShowFunc);
        this._outsideClickSubs?.unsubscribe();
        if (this.hasDetachedEventHost()) {
            // @ts-ignore
            this.detachedEventHost()!.removeEventListener('pointerenter', this.eventListenerOnShow);
            // @ts-ignore
            this.detachedEventHost()!.removeEventListener('pointerleave', this.eventListenerOnHide);
        }
    }

    ngAfterViewInit(): void {
        this.elementRef.nativeElement.addEventListener('click', this.onMobileShowFunc, true);

        if (this.hasDetachedEventHost()) {
            // @ts-ignore
            this.detachedEventHost()!.addEventListener('pointerenter', this.eventListenerOnShow, true);
            // @ts-ignore
            this.detachedEventHost()!.addEventListener('pointerleave', this.eventListenerOnHide, true);
        }
    }

    ngAfterViewChecked(): void {
        if (this.collapsibleTextLogic()) {
            if (this.elementRef.nativeElement.offsetHeight <= this.elementRef.nativeElement.parentElement.clientHeight) {
                this.forceStopDisplaying = true;
                if (this.isOpen) {
                    this.immediateHideTooltip();
                }
            } else {
                this.forceStopDisplaying = false;
            }
        }
    }

    // Desktop
    @HostListener('pointerenter', ['$event'])
    @HostListener('focus')
    @HostListener('input')
    onShow(event?: PointerEvent): void {
        if (!this.hasDetachedEventHost()) {
            this.onShowEvent(event);
        }
    }

    @HostListener('pointerleave', ['$event'])
    @HostListener('blur')
    onHide(event?: PointerEvent): void {
        if (!this.hasDetachedEventHost()) {
            this.onHideEvent(event);
        }
    }

    onDesktopHide(): void {
        if (this.isInteractive()) {
            this.interactiveHideTooltip();
        } else {
            this.immediateHideTooltip();
        }
    }

    @HostListener('pointerdown', ['$event'])
    onClick(event: PointerEvent): void {
        if (!ScreenUtils.isTouchEvent(event) && this.isOpen) {
            this.immediateHideTooltip();
        }
    }

    // Mobile
    onMobileShow(event: PointerEvent): void {
        if (this.shouldSkipEvent) {
            this.shouldSkipEvent = false;
            return;
        }
        if (!this.forceStopDisplaying && !this.isOpen && ScreenUtils.isTouchDevice()) {
            event.stopPropagation();
            if (this.isClickable()) {
                this.showClickableTooltip(event);
            } else {
                this.showTooltip();
                this.initOutsideClickSubscription();
            }
        }
    }

    private showClickableTooltip(event: PointerEvent): void {
        if (this.waitingForDoubleClick) {
            window.clearTimeout(this.doubleClickTimeout);
            this.doubleClickTimeout = undefined;
            this.waitingForDoubleClick = false;
            this.showTooltip();
            this.initOutsideClickSubscription();
            return;
        }

        this.waitingForDoubleClick = true;
        this.doubleClickTimeout = window.setTimeout(() => {
            window.clearTimeout(this.doubleClickTimeout);
            this.doubleClickTimeout = undefined;
            this.waitingForDoubleClick = false;
            this.shouldSkipEvent = true;
            event.target?.dispatchEvent(event);
        }, this.mobileShowDelay);
    }

    private initOutsideClickSubscription(): void {
        if (IsNotUndefined(this.overlayRef)) {
            this._outsideClickSubs?.unsubscribe();
            this._outsideClickSubs = this.overlayRef
                .outsidePointerEvents()
                .pipe(
                    observeOn(asyncScheduler), // prevent tooltip from hiding on event capturing
                    filter((_: MouseEvent) => ScreenUtils.isTouchDevice() && this.isOpen),
                    takeUntilDestroyed(this._destroyRef)
                )
                .subscribe(() => {
                    this.immediateHideTooltip();
                });
        }
    }

    private showTooltip(): void {
        this.show();
        this.stateChange.emit(true);
        this.cdRef.markForCheck();
    }

    private interactiveHideTooltip(): void {
        window.clearTimeout(this.timeout);
        this.timeout = window.setTimeout(() => {
            this.immediateHideTooltip();
            window.clearTimeout(this.timeout);
        }, this.interactiveHideDelay);

        this._pointerenterSub?.unsubscribe();
        this._pointerenterSub = fromEvent(this.componentAttachment?.location.nativeElement, 'pointerenter')
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(() => {
                window.clearTimeout(this.timeout);
            });

        this._pointerleaveSub?.unsubscribe();
        this._pointerleaveSub = fromEvent(this.componentAttachment?.location.nativeElement, 'pointerleave')
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(() => {
                this.interactiveHideTooltip();
            });
    }

    private immediateHideTooltip(): void {
        this._outsideClickSubs?.unsubscribe();
        this.hide();
        this.stateChange.emit(false);
    }

    private hasDetachedEventHost(): boolean {
        return !!this.detachedEventHost();
    }

    private onShowEvent(event?: PointerEvent): void {
        if (!this.forceStopDisplaying && !ScreenUtils.isTouchEvent(event) && !this.isOpen) {
            this.showTooltip();
        }
    }

    private onHideEvent(event?: PointerEvent): void {
        if (!ScreenUtils.isTouchEvent(event) && this.isOpen) {
            this.onDesktopHide();
        }
    }
}
