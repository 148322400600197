<mat-form-field appearance="outline" class="input-density w-full max-w-96 px-4">
    <mat-select [value]="locationId()" (valueChange)="onValueChange($event)">
        <mat-select-trigger>
            @if ($selectedLocation(); as selectedLocation) {
                <div class="flex flex-row items-center">
                    <div
                        class="bg-ds-bright-500 mr-2 flex h-9 min-h-9 w-9 min-w-9 items-center justify-center rounded-full text-lg text-white"
                        [ngStyle]="{ 'background-color': selectedLocation.contactColor }"
                    >
                        <span class="uppercase"> {{ selectedLocation.companyName[0] }}{{ selectedLocation.companyName[1] }} </span>
                    </div>
                    <span>{{ selectedLocation.companyName }}</span>
                </div>
            }
        </mat-select-trigger>
        @for (location of locations(); track location.locationId) {
            <mat-option [value]="location.locationId">
                <div class="flex flex-row items-center">
                    <div
                        class="bg-ds-bright-500 mr-2 flex h-9 min-h-9 w-9 min-w-9 items-center justify-center rounded-full text-lg text-white"
                        [ngStyle]="{ 'background-color': location.contactColor }"
                    >
                        <span class="uppercase">{{ location.companyName[0] }}{{ location.companyName[1] }}</span>
                    </div>
                    <span>{{ location.companyName }}</span>
                </div>
            </mat-option>
        }
    </mat-select>
</mat-form-field>
