import { type AfterViewInit, computed, Directive, effect, inject, input, output, signal, type Signal } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import {
    Calendar,
    type CalendarOptions,
    DateSelectArg,
    EventClickArg,
    EventDropArg,
    type EventInput,
    type EventSourceFuncArg,
    type PluginDef,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { type EventResizeStartArg } from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import luxonPlugin from '@fullcalendar/luxon3';
import { type ResourceFuncArg, type ResourceInput } from '@fullcalendar/resource';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { firstValueFrom, ReplaySubject } from 'rxjs';

import {
    CalendarSlotType,
    IsNotNullish,
    LocalDate,
    LocalDateTimeUtils,
    SchedulerEvent,
    SchedulerResource,
    SchedulerResourceComparatorToken,
    SchedulerSlotViewEnum,
} from '@portal/shared/utils';

export interface DateSelected {
    allDay: boolean;
    start: string;
    end: string;
    shiftId: number;
    resourceId: string;
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'full-calendar[api]',
    exportAs: 'fullCalendarApi',
})
export class PUiSchedulerApiDirective<TEvent, TResource> implements AfterViewInit {
    readonly #fullCalendar = inject(FullCalendarComponent, { host: true });
    readonly #calendarApi = signal<Calendar | null>(null);

    readonly #resourceOrder: CalendarOptions['resourceOrder'] =
        // eslint-disable-next-line @typescript-eslint/unbound-method
        inject(SchedulerResourceComparatorToken, { optional: true })?.compare ?? undefined;

    #renderedStatistic = 0;

    readonly apiSelect = output<DateSelected>({ alias: 'apiSelect' });
    readonly apiEventDrop = output<EventDropArg>({ alias: 'apiEventDrop' });
    readonly apiEventClick = output<EventClickArg>({ alias: 'apiEventClick' });

    readonly $slotTimeRange = input.required<[string, string]>({ alias: 'apiSlotTimeRange' });
    readonly #setSlotTimeRange = effect(() => {
        const slotTimeRange = this.$slotTimeRange();
        this.#calendarApi()?.setOption('slotMinTime', slotTimeRange[0]);
        this.#calendarApi()?.setOption('slotMaxTime', slotTimeRange[1]);
    });

    readonly $allowOverlap = input<boolean>(false, { alias: 'apiAllowOverlap' });
    readonly $slotDuration = input<CalendarSlotType>(SchedulerSlotViewEnum.M30, { alias: 'apiSlotDuration' });
    readonly $slotLabelInterval = input<CalendarSlotType>(SchedulerSlotViewEnum.M60, { alias: 'apiSlotLabelInterval' });

    readonly $localDate = input.required<LocalDate>({ alias: 'apiLocalDate' });
    readonly #setLocalDate = effect(() => {
        const localDate = this.$localDate();
        this.#calendarApi()?.gotoDate(LocalDateTimeUtils.toDateFormat(localDate));
    });

    readonly #eventsSource = new ReplaySubject<Array<SchedulerEvent<TEvent>>>(1);
    readonly $events = input.required<Array<SchedulerEvent<TEvent>>>({ alias: 'apiEvents' });
    readonly #setEvents = effect(() => {
        this.#eventsSource.next(this.$events());
        this.#calendarApi()?.refetchEvents();
    });

    readonly #resourcesSource = new ReplaySubject<Array<SchedulerResource<TResource>>>(1);
    readonly $resources = input.required<Array<SchedulerResource<TResource>>>({ alias: 'apiResources' });
    readonly #setResources = effect(() => {
        const resources = this.$resources();
        this.#resourcesSource.next(resources);
        this.#calendarApi()?.refetchResources();
    });

    // sync state of options
    readonly $calendarOptions: Signal<CalendarOptions> = computed(
        (
            initialView = 'resourceTimeGridDay',
            plugins: PluginDef[] = [
                dayGridPlugin,
                interactionPlugin,
                listPlugin,
                luxonPlugin,
                resourceTimeGridPlugin,
                scrollGridPlugin,
                timeGridPlugin,
            ],
            slotDuration = this.$slotDuration(),
            slotLabelInterval = this.$slotLabelInterval(),
            allowOverlap = this.$allowOverlap()
        ) => {
            const options: CalendarOptions = {
                schedulerLicenseKey: '0720423656-fcs-1732324453',
                plugins,
                initialView,
                headerToolbar: false,

                selectOverlap: allowOverlap,

                slotDuration: slotDuration,
                slotLabelInterval: slotLabelInterval,

                // stickyFooterScrollbar: true,

                businessHours: false,

                resourceOrder: this.#resourceOrder,

                // refetchResourcesOnNavigate: true,
                resourceLabelClassNames: 'items-stretch',
                resources: async (_info: ResourceFuncArg, success: (events: ResourceInput[]) => void, failure: (err: Error) => void) =>
                    this.#calendarResources(_info, success, failure),

                allDaySlot: false,
                eventConstraint: 'businessHours',
                eventOverlap: allowOverlap,
                events: async (_info: EventSourceFuncArg, success: (events: EventInput[]) => void, failure: (err: Error) => void) =>
                    this.#calendarEvents(_info, success, failure),

                // weekNumbers: true,

                // eventColor: 'transparent',

                // scrollTime: minStartTime, // timeZone: 'local',

                height: '100%',

                nowIndicator: true, // timeGrid
                nowIndicatorClassNames: [],

                dayMinWidth: 140, // scrollGridPlugin
                editable: true, // interactionPlugin
                // Allows a user to highlight multiple days or timeslots by clicking and dragging.
                selectable: true, // interactionPlugin
                selectConstraint: 'businessHours',
                // Whether clicking elsewhere on the page will cause the current selection to be cleared.
                // This option can only take effect when selectable is set to true.
                // unselectAuto: true, // interactionPlugin + { selectable: true }
                // unselectCancel: '',
                ...this.#interactionPluginRefiners, // interactionPlugin

                select: ($event: DateSelectArg) => this.#select($event),
            };

            console.log('Options: ', ++this.#renderedStatistic, ' : ', options);

            return options;
        }
    );

    #interactionPluginRefiners: CalendarOptions = {
        eventResizeStart: (arg: EventResizeStartArg): void => this.#eventResizeStart(arg),
        eventDrop: (arg: EventDropArg) => this.#eventDrop(arg),
        eventClick: (arg: EventClickArg) => this.#eventClick(arg),
    };

    #eventResizeStart(arg: EventResizeStartArg): void {
        ///
    }

    #select({ allDay, end, start, resource }: DateSelectArg): void {
        console.log('Select: ', allDay, end, start, resource);

        if (IsNotNullish(start) && IsNotNullish(end) && IsNotNullish(resource?.id) && IsNotNullish(resource?.extendedProps['shiftId'])) {
            this.apiSelect.emit({
                allDay,
                // TODO: Refactor to use a more generic property name instead of shiftId for better reusability in the shared component.
                shiftId: resource.extendedProps['shiftId'],
                start: LocalDateTimeUtils.toDateTimeFormat(start),
                end: LocalDateTimeUtils.toDateTimeFormat(end),
                resourceId: resource.id,
            });
        }
    }

    async #calendarResources(
        info: ResourceFuncArg,
        success: (resources: ResourceInput[]) => void,
        failure: (err: Error) => void
    ): Promise<void> {
        return firstValueFrom(this.#resourcesSource)
            .then((resources: Array<SchedulerResource<TResource>>) => {
                console.log('Resources: ', resources);
                success(resources);
            })
            .catch((err: Error) => {
                failure(err);
            });
    }

    async #calendarEvents(info: EventSourceFuncArg, success: (events: EventInput[]) => void, failure: (err: Error) => void): Promise<void> {
        return firstValueFrom(this.#eventsSource)
            .then((events: Array<SchedulerEvent<TEvent>>) => {
                console.log('Events: ', events);
                success(events);
            })
            .catch((err: Error) => {
                failure(err);
            });
    }

    #eventDrop(arg: EventDropArg): void {
        this.apiEventDrop.emit(arg);
    }

    #eventClick(arg: EventClickArg): void {
        this.apiEventClick.emit(arg);
    }

    ngAfterViewInit(): void {
        this.#calendarApi.set(this.#fullCalendar.getApi());
    }
}
