import { ChangeDetectionStrategy, Component, inject, output, Signal } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';

import { OptionModel, RBACContext, UserDto, UserRoles } from '@portal/shared/utils';

interface ChangeRoleDialogData {
    user: UserDto;
    $updating: Signal<boolean>;
    rbacContext: RBACContext;
}

@Component({
    templateUrl: './change-role-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatDialogClose,
        MatButtonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogContent,
        MatIconModule,
        MatProgressSpinner,
        MatDialogActions,
        MatSelectModule,
        MatDialogTitle,
        ReactiveFormsModule,
        MatDialogTitle,
    ],
})
export class ChangeRoleDialogComponent {
    readonly data = inject<ChangeRoleDialogData>(MAT_DIALOG_DATA);

    readonly changeUserRole = output<UserDto>();

    readonly roles: Array<OptionModel<UserRoles>> = [
        {
            value: UserRoles.MANAGER,
            label: 'Manager',
        },
        {
            value: UserRoles.OFFICE_ADMIN,
            label: 'Office Admin',
        },
        {
            value: UserRoles.PROVIDER,
            label: 'Provider',
        },
        {
            value: UserRoles.DIRECTOR,
            label: 'Director',
        },
    ];

    readonly changeRoleForm: FormGroup;
    private readonly fb: FormBuilder = inject(FormBuilder);

    readonly filteredRoles: Array<OptionModel<UserRoles>> = this.data.rbacContext.isManager
        ? this.roles.filter((role) => role.value !== UserRoles.MANAGER && role.value !== UserRoles.DIRECTOR)
        : this.roles;

    constructor() {
        this.changeRoleForm = this.fb.group({
            roles: [this.data?.user.roles?.[0] ?? UserRoles.MANAGER],
        });
    }

    onChangeRole() {
        const selectedRole = this.changeRoleForm.get('roles')?.value;
        const updatedUserData = {
            ...this.data.user,
            roles: [selectedRole],
        };
        this.changeUserRole.emit(updatedUserData);
    }
}
