@let user = $user();
<div class="shadow-3xl mt-3 flex w-full flex-col gap-3 rounded-md bg-white p-4 2xl:w-9/12">
    <span class="text-gray-400">Last signed in</span>
    <span class="text-sm">{{ user.loginHistoryDto | formatLoginDate }}</span>
</div>
@if (user.loginHistoryDto | hasItems) {
    <div class="mt-6 flex flex-col lg:max-w-lg">
        <span class="mb-3 flex font-bold">Recent logins</span>
        <section>
            <table mat-table [dataSource]="$loginHistory()" class="!bg-transparent">
                <!-- IP Column -->
                <ng-container matColumnDef="ip">
                    <th mat-header-cell *matHeaderCellDef class="whitespace-nowrap px-6 py-4">IP address</th>
                    <td mat-cell *matCellDef="let element" class="whitespace-nowrap px-6 py-4">{{ element.ipAddress }}</td>
                </ng-container>

                <!-- Date Column -->
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef class="whitespace-nowrap px-6 py-4">Date and Time</th>
                    <td mat-cell *matCellDef="let element" class="whitespace-nowrap text-wrap px-6 py-4">
                        {{ element.loginDate | date }}, ({{ element.loginDate | timeAgo }})
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </section>
    </div>
} @else {
    <div class="mt-6 flex flex-col items-center 2xl:w-9/12">
        <mat-icon class="!flex !h-[50px] !w-[50px] items-center justify-center text-[3rem] text-gray-500">list</mat-icon>
        <span class="mb-3 flex text-gray-500">Never logged in</span>
    </div>
}
