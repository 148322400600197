import {
    AfterViewInit,
    ContentChildren,
    DestroyRef,
    Directive,
    ElementRef,
    inject,
    NgZone,
    output,
    OutputEmitterRef,
    QueryList,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { merge, Observable, Observer, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive({
    selector: '[pUiClampItem]',
})
export class PUiClampItemDirective {
    readonly elementRef = inject(ElementRef);
}

@Directive({
    selector: '[pUiClampContainer]',
})
export class PUiClampContainerDirective implements AfterViewInit {
    readonly clampCountChange: OutputEmitterRef<number> = output<number>();

    readonly #destroyRef: DestroyRef = inject(DestroyRef);
    readonly #clampContainer: ElementRef = inject(ElementRef);
    readonly #ngZone: NgZone = inject(NgZone);

    @ContentChildren(PUiClampItemDirective) private readonly clampItemQueryList: QueryList<PUiClampItemDirective> | undefined;

    ngAfterViewInit(): void {
        this.#hiddenClampItemCountChanges()
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe((counter: number) => this.clampCountChange.emit(counter));
    }

    #hiddenClampItemCountChanges(): Observable<number> {
        return merge(
            this.#elementResizeChanges(this.#clampContainer.nativeElement),
            this.clampItemQueryList!.changes.pipe(
                switchMap((queryList: QueryList<PUiClampItemDirective>) =>
                    merge(...queryList.map((el: PUiClampItemDirective) => this.#elementResizeChanges(el.elementRef.nativeElement)))
                )
            )
        ).pipe(
            map(() => {
                const directives: PUiClampItemDirective[] = this.clampItemQueryList!.toArray();

                const containerRect: DOMRectReadOnly = this.#clampContainer.nativeElement.getBoundingClientRect();
                const right = Math.ceil(containerRect.right),
                    bottom = Math.ceil(containerRect.bottom),
                    left = Math.floor(containerRect.left),
                    top = Math.floor(containerRect.top);

                const elements: Element[] = directives.map((directive: PUiClampItemDirective) => directive.elementRef.nativeElement);

                const firstOverflowedIdx: number = elements.findIndex((child: Element) => {
                    const childRect: DOMRect = child.getBoundingClientRect();
                    return childRect.right > right || childRect.bottom > bottom || childRect.left < left || childRect.top < top;
                });

                return firstOverflowedIdx === -1 ? 0 : elements.length - firstOverflowedIdx;
            })
        );
    }

    #elementResizeChanges(element: Element): Observable<void> {
        return new Observable((observer: Observer<void>) => {
            const resizeObserver: ResizeObserver = new ResizeObserver((_: ResizeObserverEntry[]) =>
                this.#ngZone.run(() => observer.next())
            );
            resizeObserver.observe(element);
            return () => resizeObserver.unobserve(element);
        });
    }
}
