import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

import { ConfirmationDialogData } from './confirmation-dialog.data';

@Component({
    template: `
        <h1 mat-dialog-title>{{ data.title }}</h1>
        <mat-dialog-content>
            <p class="w-full py-2">{{ data.message }}</p>
            @if (data.typeToEnable) {
                <mat-form-field appearance="outline">
                    <input type="text" autoFocus matInput [formControl]="typeToConfirmControl" />
                </mat-form-field>
            }
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-button [mat-dialog-close]="false" type="button">{{ data.cancelButtonText || 'Cancel' }}</button>
            <button
                mat-raised-button
                [mat-dialog-close]="true"
                type="button"
                class="primary"
                [disabled]="data.typeToEnable && data.typeToEnable !== typeToConfirmControl.value"
            >
                {{ data.confirmButtonText || 'Confirm' }}
            </button>
        </mat-dialog-actions>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatButton, MatDialogClose, MatFormField, MatInput, ReactiveFormsModule],
})
export class ConfirmationDialogComponent {
    readonly data = inject<ConfirmationDialogData>(MAT_DIALOG_DATA);

    readonly typeToConfirmControl = new FormControl<string | null>(null);
}
