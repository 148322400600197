<div class="flex h-full w-full">
    @if (asideTpl$ | async; as portal) {
        <ng-teamplte [cdkPortalOutlet]="portal" />
    }
    <mat-sidenav-container class="w-full" (backdropClick)="onBackdropClick()">
        <mat-sidenav
            #matSidenavRef
            [opened]="panelSidenavOpened$ | async"
            [mode]="$sidenavMode()"
            (openedStart)="onSidenavOpen()"
            (openedChange)="onSidenavOpenChange($event)"
            position="start"
        >
            @if (panelSidenavTpl$ | async; as portal) {
                @if ($sidenavOpened()) {
                    <ng-teamplte [cdkPortalOutlet]="portal" />
                }
            }
        </mat-sidenav>

        <mat-sidenav-content>
            <div class="flex h-full flex-col overflow-hidden">
                <main class="relative grow overflow-auto">
                    @if (contentTpl$ | async; as portal) {
                        <ng-teamplte [cdkPortalOutlet]="portal" />
                    }
                </main>
                @if (footerTpl$ | async; as portal) {
                    <ng-teamplte [cdkPortalOutlet]="portal" />
                }
            </div>
        </mat-sidenav-content>

        <mat-sidenav
            #matDrawerRef
            [opened]="panelDrawerOpened$ | async"
            (openedStart)="onDrawerOpen()"
            (openedChange)="onDrawerOpenChange($event)"
            [disableClose]="true"
            [mode]="$drawerMode()"
            position="end"
        >
            @if ($panelDrawerPortal(); as portal) {
                @if ($drawerOpened()) {
                    <ng-teamplte [cdkPortalOutlet]="portal" />
                }
            }
        </mat-sidenav>
    </mat-sidenav-container>
</div>
