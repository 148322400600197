import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { LogoTypes, minLogoSize } from '@portal/shared/utils';

@Component({
    selector: 'p-ui-logo',
    templateUrl: './logo.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule],
})
export class LogoComponent {
    readonly type = input<LogoTypes>(LogoTypes.Colored);
    readonly height = input<number>(minLogoSize);
    readonly width = input<number>(minLogoSize);
    protected readonly LogoTypes = LogoTypes;
}
