@let fileViewLoadingState = $fileViewLoadingState();
@let filesDeleting = $filesDeleting();
@let filesUploading = $filesUploading();
@let filesDownloading = $filesDownloading();
@let fileToHighlight = $lastUploadedFileId();

@if ((filesUploading | hasItems) || ($files() | hasItems) || ($attachementErrors() | hasItems)) {
    <div class="shadow-3xl mb-3 flex w-full flex-row flex-wrap justify-start gap-5 rounded-md bg-white p-4">
        @for (attachementError of $attachementErrors(); track $index) {
            <div class="group relative flex w-full flex-col flex-wrap items-center sm:w-[calc(50%-10px)] 2xl:w-[calc(25%-15px)]">
                <div class="absolute right-0 top-0 z-10 mr-0.5 mt-0.5 flex px-0.5 py-0.5 md:hidden md:group-hover:flex">
                    <span class="absolute inset-0 flex items-center justify-center rounded-xl bg-black bg-opacity-80"></span>
                    <div class="icon-button-density flex gap-0.5">
                        <button class="!text-white" mat-icon-button (click)="onRemoveAttachementError($index)">
                            <mat-icon class="text-xl leading-none">close</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="flex h-52 w-full flex-col items-center justify-center gap-3 border border-solid border-current border-gray-400">
                    <div class="flex items-center gap-1">
                        <mat-icon class="text-ds-orange-500">warning</mat-icon> {{ attachementError.attachementSize.toFixed(1) }} MB
                    </div>
                    <div class="text-red-500">File size exceeds limit</div>
                </div>

                <div class="mt-1 w-full truncate break-words text-center text-sm group-hover:whitespace-normal sm:max-w-44">
                    {{ attachementError.attachementName }}
                </div>
            </div>
        }

        @for (fileUploading of filesUploading; track fileUploading.uploadingId) {
            <div class="group flex w-full flex-col flex-wrap items-center sm:w-[calc(50%-10px)] 2xl:w-[calc(25%-15px)]">
                <div class="flex h-52 w-full flex-row items-center justify-center border border-solid border-current border-gray-400">
                    <mat-progress-spinner [diameter]="30" [value]="fileUploading.uploadingProgress" />
                </div>

                <div class="mt-1 w-full truncate break-words text-center text-sm group-hover:whitespace-normal sm:max-w-44">
                    {{ fileUploading.attachementName }}
                </div>
            </div>
        }

        @for (file of $descFiles(); track file.fileId) {
            @let isViewLoading = fileViewLoadingState[file.fileId!] || fileViewLoadingState[file.caseFileStorageId!];
            @let isDeleting = filesDeleting[file.fileId!] || filesDeleting[file.caseFileStorageId!];
            @let isDownloading = filesDownloading[file.fileId!] || filesDownloading[file.caseFileStorageId!];

            <div class="group flex w-full flex-col flex-wrap items-center sm:w-[calc(50%-10px)] 2xl:w-[calc(25%-15px)]">
                @if (isViewLoading || isDeleting || isDownloading) {
                    <div class="flex h-52 w-full flex-row items-center justify-center border border-solid border-current border-gray-400">
                        <mat-spinner [diameter]="30" />
                    </div>
                } @else {
                    <div class="relative w-full">
                        <div
                            class="absolute right-0 top-0 z-10 mr-0.5 mt-0.5 flex px-1 py-0.5 md:hidden md:group-hover:flex"
                            [class.!flex]="fileId === file.fileId"
                        >
                            <span class="absolute inset-0 flex items-center justify-center rounded-xl bg-black bg-opacity-80"></span>
                            <div class="icon-button-density flex gap-0.5">
                                <button class="!text-white" mat-icon-button (click)="onDownloadFile(file)" [disabled]="isDownloading">
                                    <mat-icon class="text-xl leading-none">download</mat-icon>
                                </button>
                                <button class="!text-white" mat-icon-button [matMenuTriggerFor]="menu" (click)="onOpenMenu(file.fileId)">
                                    <mat-icon class="text-xl leading-none">more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu" (menuOpened)="onOpenMenu(file.fileId)" (closed)="onCloseMenu()">
                                    <button mat-menu-item (click)="onViewFile(file, FileViewMode.Viewer)">
                                        <mat-icon>visibility</mat-icon>
                                        <span>View</span>
                                    </button>
                                    <button mat-menu-item (click)="onDownloadFile(file)" [disabled]="isDownloading">
                                        <mat-icon>download</mat-icon>
                                        <span>Download</span>
                                    </button>
                                    <button mat-menu-item (click)="onDeleteFileDialog(file)" [disabled]="isDeleting">
                                        <mat-icon>delete</mat-icon>
                                        <span>Delete</span>
                                    </button>
                                </mat-menu>
                            </div>
                        </div>
                        <div
                            class="relative flex h-52 w-full cursor-pointer flex-row items-center justify-center border border-solid border-current border-gray-400"
                            [class.fadeout]="fileToHighlight === file.fileId"
                            (click)="onViewFile(file, FileViewMode.Viewer)"
                            (animationend)="onHighlightEnd()"
                        >
                            @switch (file.fileType | fileType) {
                                @case (FileType.Image) {
                                    <mat-icon class="file-icon !h-24 !w-24 text-8xl"> image</mat-icon>
                                }
                                @case (FileType.PDF) {
                                    <mat-icon class="file-icon !h-24 !w-24 text-8xl"> picture_as_pdf</mat-icon>
                                }
                                @case (FileType.Document) {
                                    <mat-icon class="file-icon !h-24 !w-24 text-8xl"> docs</mat-icon>
                                }
                                @case (FileType.Video) {
                                    <mat-icon class="file-icon !h-24 !w-24 text-8xl"> video_file</mat-icon>
                                }
                                @case (FileType.Audio) {
                                    <mat-icon class="file-icon !h-24 !w-24 text-8xl"> audio_file</mat-icon>
                                }
                                @case (FileType.Spreadsheet) {
                                    <mat-icon class="file-icon !h-24 !w-24 text-8xl"> data_table</mat-icon>
                                }
                                @case (FileType.Unsupported) {
                                    <mat-icon class="file-icon !h-24 !w-24 text-8xl"> unknown_document</mat-icon>
                                }
                            }
                        </div>
                    </div>
                }
                <div class="mt-1 w-full truncate break-words text-center text-sm group-hover:whitespace-normal sm:max-w-44">
                    {{ file.name }}
                </div>
                <span class="mt-1 text-xs text-gray-400">{{ file.created | date }}</span>
            </div>
        }
    </div>
}
