import { ComponentType, ConnectionPositionPair, OverlayConfig } from '@angular/cdk/overlay';
import { Directive, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { BaseOverlay } from './base-overlay';
import { PUIPortalComponent } from '../../portal';
import { BASE_OVERLAY_CLASS, TOOLTIP_BASE_OVERLAY_CLASS } from '../constants/constants';
import { PortalPlacement } from '../constants/portal-placement';
import { PORTAL_POSITIONS_MAP } from '../constants/portal-positions-map';
import { PortalTemplateProps } from '../interfaces/portal-template-props';

const TOOLTIP_MAX_WIDTH: number = 352;
const VERTICAL_INDENT: number = 8;

@Directive()
export abstract class BaseTooltip extends BaseOverlay<PUIPortalComponent> implements OnInit, OnDestroy {
    displayValue!: TemplateRef<PortalTemplateProps> | string;
    placement!: PortalPlacement;
    override config: OverlayConfig | undefined;
    templateProps: unknown;

    private positionChangeSubs?: Subscription;
    protected override ComponentToAttach: ComponentType<PUIPortalComponent> = PUIPortalComponent;
    protected override readonly baseOverlayClass: string[] = [TOOLTIP_BASE_OVERLAY_CLASS, BASE_OVERLAY_CLASS];

    private get portalCmp(): PUIPortalComponent {
        return this.componentAttachment!.instance;
    }

    ngOnInit(): void {
        this.initConfig();
        this.initPositions();
    }

    override ngOnDestroy(): void {
        if (this.isOpen) {
            this.hide();
        }
        this.positionChangeSubs?.unsubscribe();
    }

    private initPositions(): void {
        this.positions = (PORTAL_POSITIONS_MAP.get(this.placement) ?? []).map((value: ConnectionPositionPair) => {
            return new ConnectionPositionPair(
                { originX: value.originX, originY: value.originY },
                { overlayX: value.overlayX, overlayY: value.overlayY },
                value.offsetX ? this.getNewOffsetValue(value.offsetX) : undefined,
                value.offsetY ? this.getNewOffsetValue(value.offsetY) : undefined,
                []
            );
        });
    }

    private getNewOffsetValue(prevOffset: number): number {
        if (!prevOffset) {
            return 0;
        }
        return prevOffset > 0 ? VERTICAL_INDENT : -VERTICAL_INDENT;
    }

    private initConfig(): void {
        if (this.config) {
            this.config.maxWidth ??= this.config.width ?? TOOLTIP_MAX_WIDTH;
        } else {
            this.config = new OverlayConfig({
                maxWidth: TOOLTIP_MAX_WIDTH,
            });
        }
    }

    protected override show(): void {
        super.show();

        if (typeof this.displayValue === 'string') {
            this.portalCmp.text = this.displayValue as string;
        }
        // TODO validate instance of
        else if (this.displayValue instanceof TemplateRef) {
            this.portalCmp.view = this.displayValue as TemplateRef<PortalTemplateProps>;
            this.portalCmp.templateProps = this.templateProps;
        }
        this.portalCmp.placement = this.placement;
        this.portalCmp.closeFn = () => {
            this.hide();
        };
    }
}
