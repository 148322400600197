import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, inject, output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';

import { generatePassword, UserDto } from '@portal/shared/utils';

@Component({
    templateUrl: './reset-password-dialog.component.html',
    imports: [
        MatDialogClose,
        MatButtonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogContent,
        MatDialogActions,
        MatCheckboxModule,
        MatIconModule,
        ReactiveFormsModule,
        MatDialogTitle,
        MatTooltip,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordDialogComponent {
    readonly data = inject<UserDto>(MAT_DIALOG_DATA);
    readonly #clipboard = inject(Clipboard);

    readonly resetUserPassword = output<{ user: UserDto; notify: boolean }>();

    readonly resetPasswordForm: FormGroup;
    private readonly fb: FormBuilder = inject(FormBuilder);

    constructor() {
        this.resetPasswordForm = this.fb.group({
            password: [generatePassword(), Validators.required],
            sendNotification: [true],
        });
    }

    copyToClipboard(value: string): void {
        this.#clipboard.copy(value);
    }

    generatePassword(): void {
        this.resetPasswordForm.get('password')?.setValue(generatePassword());
    }

    onResetPassword() {
        if (this.resetPasswordForm.valid) {
            const password = this.resetPasswordForm.get('password')?.value;
            const notify = this.resetPasswordForm.get('sendNotification')?.value;
            const user = {
                ...this.data,
                password: password,
            };
            this.resetUserPassword.emit({ user, notify });
        }
    }
}
