<form (submit)="onSubmit($event)" class="relative flex w-full items-center" novalidate>
    <mat-form-field appearance="outline" class="w-full">
        <button (click)="onSearch()" mat-icon-button matPrefix type="button">
            <mat-icon>search</mat-icon>
        </button>
        <input
            #input
            (blur)="onBlur()"
            (focus)="onFocus()"
            (keyup.escape)="onEscape()"
            [formControl]="formControl"
            [placeholder]="$placeholder()"
            enterkeyhint="search"
            matInput
            class="[&::-webkit-search-cancel-button]:hidden"
            type="search"
        />
        @if (formControl.value) {
            <button mat-icon-button (click)="onClear()" type="button" matSuffix>
                <mat-icon>close</mat-icon>
            </button>
        }
    </mat-form-field>
</form>
