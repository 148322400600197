import { Directive, inject, TemplateRef, input, AfterViewInit, OnDestroy } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';

import { CONTAINER_SHELL_HOST, ContainerPanelType, ContainerShellHost, ContainerType } from './container.utils';

@Directive({
    selector: '[pUiContainerPanel]',
})
export class ContainerPanelDirective implements ContainerType<ContainerPanelType>, AfterViewInit, OnDestroy {
    readonly #shellHost = inject(CONTAINER_SHELL_HOST, { optional: true });
    #host: ContainerShellHost | null = null;

    readonly $panelType = input.required<ContainerPanelType>({ alias: 'pUiContainerPanel' });
    readonly $hostName = input<string | undefined>(undefined, { alias: 'pUiContainerPanelHostName' });
    readonly $opened = input<boolean>(undefined, { alias: 'pUiContainerPanelOpened' });
    readonly mode = input<MatDrawerMode>(undefined, { alias: 'pUiContainerPanelMode' });
    readonly static = input<boolean>(true, { alias: 'pUiContainerPanelStatic' });

    readonly templateRef = inject(TemplateRef);

    ngOnDestroy(): void {
        if (!this.static()) {
            this.#host?.removePanel(this.$panelType(), this.$hostName());
        }
    }

    ngAfterViewInit(): void {
        if (!this.static()) {
            this.#host = this.#shellHost?.getHost(this.$hostName()) ?? null;
            this.#host?.addPanel(this.$panelType(), this.templateRef, this.$hostName());
        }
    }

    getContainerType(): ContainerPanelType {
        return this.$panelType();
    }
}
