import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    DestroyRef,
    Directive,
    ElementRef,
    inject,
    Input,
    OnInit,
    TemplateRef,
    viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, ControlValueAccessor, FormControl, ReactiveFormsModule, ValidationErrors, Validator } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatOption } from '@angular/material/autocomplete';
import { MatChipGrid, MatChipInput, MatChipInputEvent, MatChipRemove, MatChipRow } from '@angular/material/chips';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject, switchMap } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { FormUtils } from '@portal/shared/utils';

export class TContext<T> {
    $implicit!: T;
}

@Directive({
    selector: 'ng-template[pUiChipFilterDisplay]',
})
export class ChipFilterDisplayDirective<T> {
    readonly templateRef = inject<TemplateRef<TContext<T>>>(TemplateRef);
}

@Component({
    imports: [
        MatFormField,
        MatChipGrid,
        AsyncPipe,
        NgTemplateOutlet,
        MatIcon,
        ReactiveFormsModule,
        MatAutocompleteTrigger,
        MatChipInput,
        MatAutocomplete,
        MatOption,
        MatLabel,
        MatChipRow,
        MatChipRemove,
    ],
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'cc-chip-filter-control',
    templateUrl: './chip-filter-control.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        FormUtils.controlValueAccessorFactory(ChipFilterControlComponent),
        FormUtils.controlValidatorsFactory(ChipFilterControlComponent),
    ],
})
export class ChipFilterControlComponent<T> implements OnInit, ControlValueAccessor, Validator {
    readonly #destroyRef = inject(DestroyRef);

    onChange?: (value: T[]) => void;
    onTouched?: () => void;

    @Input() label?: string;
    @Input() placeholder?: string;
    @Input({ required: true }) comparator!: (a: T, b: T) => boolean;
    @Input({ required: true }) searcher!: (item: T, input: string) => boolean;

    @ContentChild(ChipFilterDisplayDirective<T>, {
        static: false,
    })
    displayViewTpl?: ChipFilterDisplayDirective<T>;

    readonly $inputElement = viewChild.required<ElementRef<HTMLInputElement>>('inputRef');

    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    readonly inputControl: FormControl<T | string | null> = new FormControl<T | string | null>(null);

    private readonly filteredItemsSource = new ReplaySubject<readonly T[]>(1);
    private readonly valueSelectedItemsSource = new BehaviorSubject<T[]>([]);

    readonly filteredList$: Observable<readonly T[]> = this.filteredItemsSource.asObservable();
    readonly valueSelectedItems$: Observable<readonly T[]> = this.valueSelectedItemsSource.asObservable();

    private readonly itemsSource: Subject<T[]> = new ReplaySubject<T[]>(1);

    disabled: boolean = false;
    readonly allItemsSelected$: Observable<boolean> = combineLatest([this.valueSelectedItemsSource, this.itemsSource]).pipe(
        map(([selectedItems, items]: [T[], T[]]) => selectedItems.length === items.length)
    );

    @Input({ required: true })
    set items(value: T[]) {
        if (value != null) {
            this.itemsSource.next(value);
        }
    }

    private readonly addSelectedItemEventSource: Subject<T> = new Subject<T>();
    private readonly removeSelectedItemEventSource: Subject<T> = new Subject<T>();

    ngOnInit(): void {
        this.removeSelectedItemEventSource.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe((removeItem: T) => {
            this.valueSelectedItemsSource.next(
                this.valueSelectedItemsSource.getValue().filter((item: T) => !this.comparator(item, removeItem))
            );
        });

        this.addSelectedItemEventSource.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe((addItem: T) => {
            this.valueSelectedItemsSource.next([...this.valueSelectedItemsSource.getValue(), addItem]);
        });

        this.allItemsSelected$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe((allItemsSelected: boolean) => {
            if (allItemsSelected) {
                this.inputControl.disable();
            } else {
                this.inputControl.enable();
            }
        });

        combineLatest([this.itemsSource, this.valueSelectedItemsSource])
            .pipe(
                map(([items, selectedItems]: [T[], T[]]) =>
                    items.filter((item: T) => !selectedItems.find((selectedItem: T) => this.comparator(item, selectedItem)))
                ),
                switchMap((items: T[]) =>
                    this.inputControl.valueChanges.pipe(
                        filter((search: T | string | null) => typeof search === 'string'),
                        map((search: string) => items.filter((item: T) => search !== '' && this.searcher(item, search)))
                    )
                ),
                takeUntilDestroyed(this.#destroyRef)
            )
            .subscribe((items: T[]) => {
                this.filteredItemsSource.next(items);
            });

        this.valueSelectedItemsSource
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe((selectedItems: T[]) => this.onChange?.(selectedItems));
    }

    writeValue(value: T[]): void {
        this.valueSelectedItemsSource.next(value);
    }

    registerOnChange(fn: (value: T[]) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return null;
    }

    registerOnValidatorChange(fn: () => void): void {
        this.onChange = fn;
    }

    onAddItem(event: MatChipInputEvent): void {
        // TODO add or remove token
    }

    onRemoveItem(removeItem: T): void {
        this.removeSelectedItemEventSource.next(removeItem);
    }

    onSelectItem(event: MatAutocompleteSelectedEvent): void {
        this.addSelectedItemEventSource.next(event.option.value as T);
        this.$inputElement().nativeElement.value = '';
        this.inputControl.setValue('');
    }
}
