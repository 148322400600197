import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, output, input, computed } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';

import { HeaderLocationModel } from '@portal/shared/utils';

@Component({
    selector: 'p-ui-header-location',
    templateUrl: './header-location.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatSelectModule, NgStyle],
})
export class HeaderLocationComponent {
    readonly locations = input<HeaderLocationModel[]>([]);
    readonly locationId = input<number | null>(null);
    readonly locationIdChange = output<number>();

    readonly $selectedLocation = computed((locations = this.locations(), locationId = this.locationId()) =>
        locations.find((location) => location.locationId === locationId)
    );

    onValueChange(locationId: number): void {
        this.locationIdChange.emit(locationId);
    }
}
